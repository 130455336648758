import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

export const MuiTab: MuiComponentOverride<'MuiTab'> = {
  styleOverrides: {
    root: {
      padding: theme.spacing(0, 0, 8),
      mb: theme.spacing(8),
      minHeight: 'unset',
      fontSize: theme.spacing(18),
      lineHeight: theme.spacing(24),
      minWidth: 0,
      color: theme.palette.grey['900'],
      '&.Mui-selected': { color: theme.palette.grey['900'] },

      [theme.breakpoints.up('lg')]: {
        fontSize: theme.spacing(22),
        lineHeight: theme.spacing(26),
      },
    },
  },
};
