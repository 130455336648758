import { MuiComponentOverride } from '@model/mui-component-override';
import theme from './theme';

export const MuiOutlinedInput: MuiComponentOverride<'MuiOutlinedInput'> = {
  styleOverrides: {
    root: {
      borderRadius: theme.spacing(12),
      padding: `${theme.spacing(12)} ${theme.spacing(16)}`,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.alphaWhite['50'],
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.alphaWhite['50'],
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '.MuiOutlinedInput-input': {
        padding: `0 0 ${theme.spacing(2)} 0`,
        color: theme.palette.common.white,
      },
    },
  },
};
