import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    primaryOnDark: true;
  }
}

export const checkboxStylesMap = {
  primary: {
    color: theme.palette.primary.main,
  },
  primaryOnDark: {
    color: theme.palette.common.white,

    ':before': {
      content: '""',
      position: 'absolute',
      left: theme.spacing(13),
      top: theme.spacing(13),
      backgroundColor: theme.palette.primary.main,
      width: `calc(100% - ${theme.spacing(26)})`,
      height: `calc(100% - ${theme.spacing(26)})`,
    },

    ':hover': {
      color: theme.palette.grey['100'],
    },

    ':hover:before': {
      backgroundColor: theme.palette.primary['900'],
    },

    ':not(&.Mui-checked):before, &.Mui-disabled:before': {
      backgroundColor: 'transparent',
    },
  },
};

export const MuiCheckbox: MuiComponentOverride<'MuiCheckbox'> = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      marginRight: theme.spacing(7),
      ...checkboxStylesMap[ownerState.color || 'checkboxPrimary'],
      svg: {
        position: 'relative',
      },
    }),
  },
};
