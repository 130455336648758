import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    primaryOnDark: true;
  }
}

const thumbStylesMap = {
  primary: {
    boxShadow: `0 ${theme.spacing(2)} ${theme.spacing(8)} rgba(0,0,0,.16)`,
  },
  primaryOnDark: {
    boxShadow: 'none',
  },
};

const trackStylesMap = {
  primary: {
    backgroundColor: theme.palette.grey['500'],
  },
  primaryOnDark: {
    backgroundColor: theme.palette.grey['400'],
  },
};

const switchBaseStylesMap = {
  primary: {
    '&:not(.Mui-disabled) + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey['700'],
    },

    '&:not(.Mui-disabled).Mui-checked': {
      '.MuiSwitch-thumb': {
        backgroundColor: theme.palette.common.white,
      },

      '+ .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },

      ':hover + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary['900'],
      },
    },

    '&.Mui-disabled': {
      '.MuiSwitch-thumb': {
        boxShadow: 'none',
        backgroundColor: theme.palette.grey['300'],
      },
    },

    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.grey['200'],
    },
  },
  primaryOnDark: {
    ':hover:not(.Mui-disabled) + .MuiSwitch-track': {
      backgroundColor: theme.palette.grey['600'],
      opacity: 1,
    },

    '&:not(.Mui-disabled).Mui-checked': {
      '.MuiSwitch-thumb': {
        backgroundColor: theme.palette.common.white,
      },
    },

    '&.Mui-disabled': {
      '.MuiSwitch-thumb': {
        backgroundColor: theme.palette.alphaWhite['70'],
      },

      '.MuiSwitch-track': {
        backgroundColor: theme.palette.alphaWhite['10'],
      },
    },

    '&.Mui-disabled + .MuiSwitch.track': {},
  },
};

export const MuiSwitch: MuiComponentOverride<'MuiSwitch'> = {
  styleOverrides: {
    switchBase: ({ ownerState }) => ({
      ...switchBaseStylesMap[ownerState.color],
    }),
    thumb: ({ ownerState }) => ({
      ...thumbStylesMap[ownerState.color],
    }),
    track: ({ ownerState }) => ({
      opacity: 1,
      ...trackStylesMap[ownerState.color],
    }),
  },
};
