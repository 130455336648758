import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

export const MuiDivider: MuiComponentOverride<'MuiDivider'> = {
  variants: [
    {
      props: { variant: 'separator' },
      style: {
        height: theme.spacing(16),
        alignSelf: 'unset',
      },
    },
  ],
};

declare module '@mui/material/Divider' {
  export interface DividerPropsVariantOverrides {
    separator: true;
  }
}
