import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

export const MuiTabs: MuiComponentOverride<'MuiTabs'> = {
  styleOverrides: {
    root: {
      minHeight: 'unset',
    },
    flexContainer: {
      gap: theme.spacing(32),
    },
  },
};
