import { MuiButton } from './mui-button';
import { MuiAccordion } from './mui-accordion';
import theme from './theme';
import { MuiFormControlLabel } from '@mui/mui-form-control-label';
import { MuiCheckbox } from '@mui/mui-checkbox';
import { MuiRadio } from '@mui/mui-radio';
import { MuiSwitch } from '@mui/mui-switch';
import { MuiTextField } from '@mui/mui-text-field';
import { MuiFormControl } from '@mui/mui-form-control';
import { MuiPagination, MuiPaginationItem } from '@mui/mui-pagination';
import { MuiChip } from '@mui/mui-chip';
import { MuiDivider } from '@mui/mui-divider';
import { MuiMenu } from '@mui/mui-menu';
import { MuiTab } from '@mui/mui-tab';
import { MuiTabs } from '@mui/mui-tabs';
import { MuiInput } from '@mui/mui-input';
import { MuiAutocomplete } from '@mui/mui-autocomplete';
import { MuiSelect } from './mui-select';
import { MuiOutlinedInput } from './mui-outlined-input';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    accordionMenuLight: true;
    accordionMenuDark: true;
  }
}

theme.components = {
  MuiAccordion,
  MuiButton,
  MuiCheckbox,
  MuiChip,
  MuiInput,
  MuiAutocomplete,
  MuiDivider,
  MuiFormControl,
  MuiFormControlLabel,
  MuiMenu,
  MuiTab,
  MuiSelect,
  MuiTabs,
  MuiPagination,
  MuiPaginationItem,
  MuiRadio,
  MuiSwitch,
  MuiTextField,
  MuiOutlinedInput,
};
