import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

export const MuiAutocomplete: MuiComponentOverride<'MuiAutocomplete'> = {
  styleOverrides: {
    input: {
      '&.MuiInput-input': {
        padding: theme.spacing(12, 0),
      },
    },
  },
};
