import theme from './theme';
import { MuiComponentOverride } from '@model/mui-component-override';

declare module '@mui/material/Pagination' {
  interface PaginationPropsVariantOverrides {
    onLight: true;
    onDark: true;
    noMargin: true;
  }
}

export const MuiPaginationItem: MuiComponentOverride<'MuiPaginationItem'> = {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,

        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
};

export const MuiPagination: MuiComponentOverride<'MuiPagination'> = {
  styleOverrides: {
    root: {
      marginTop: theme.spacing(32),

      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(80),
      },
    },
    ul: {
      justifyContent: 'center',
    },
  },
  variants: [
    {
      props: { variant: 'onLight' },
      style: {
        '& .MuiPaginationItem-root': {
          color: theme.palette.common.black,

          '&.Mui-selected': {
            color: theme.palette.common.white,
          },
        },
      },
    },
    {
      props: { variant: 'onDark' },
      style: {
        '& .MuiPaginationItem-root': {
          color: theme.palette.common.white,
        },
      },
    },
  ],
};
