import { MuiComponentOverride } from '@model/mui-component-override';
import { Interpolation, Theme } from '@mui/material';
import theme from './theme';

const sharedButtonStyles: Interpolation<{ theme: Theme }> = {
  borderRadius: theme.spacing(8),
  fontWeight: 500,
  minWidth: theme.spacing(184),
  fontFamily: 'Roboto, sans-serif',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(8),
  textTransform: 'uppercase',
  '.buttonText': {
    padding: theme.spacing(1.5, 0),
  },
};

const getIconStyle = (ownerState) => {
  const baseStyle = {
    margin: 0,
    svg: {
      width: theme.spacing(24),
      height: theme.spacing(24),
    },
  };

  switch (ownerState.color) {
    case 'primary':
      return {
        ...baseStyle,
        color: ownerState.disabled ? theme.palette.grey['400'] : theme.palette.common.white,
      };

    case 'secondary':
      return {
        ...baseStyle,
        color: ownerState.disabled ? theme.palette.grey['400'] : theme.palette.primary['700'],
      };

    case 'tertiary':
      return {
        ...baseStyle,
        color: ownerState.disabled ? theme.palette.grey['400'] : theme.palette.primary['700'],
      };

    case 'link':
      return {
        ...baseStyle,
        color: ownerState.disabled ? theme.palette.grey['400'] : theme.palette.primary['700'],
      };

    case 'primaryOnDark':
    case 'secondaryOnDark':
    case 'tertiaryOnDark':
    case 'linkOnDark':
      return {
        ...baseStyle,
      };
  }
};

export const MuiButton: MuiComponentOverride<'MuiButton'> = {
  styleOverrides: {
    root: ({ ownerState }) => {
      switch (ownerState.color) {
        case 'primary':
          return {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,

            ':hover, :focus-visible': {
              backgroundColor: theme.palette.primary['900'],
            },

            ':disabled': {
              color: theme.palette.grey['400'],
              backgroundColor: theme.palette.grey['200'],
            },
          };

        case 'primaryOnDark':
          return {
            color: theme.palette.grey['900'],
            backgroundColor: theme.palette.common.white,

            ':hover, :focus-visible': {
              backgroundColor: theme.palette.grey['100'],
            },
          };

        case 'secondary':
          return {
            color: theme.palette.grey['900'],
            border: `${theme.spacing(3)} solid ${theme.palette.primary.main}`,

            ':hover, :focus-visible': {
              backgroundColor: theme.palette.common.white,
              borderColor: theme.palette.primary['900'],
              svg: {
                color: theme.palette.primary['900'],
              },
            },

            ':disabled': {
              color: theme.palette.grey['400'],
              borderColor: theme.palette.grey['200'],
            },
          };

        case 'secondaryOnDark':
          return {
            color: theme.palette.common.white,
            border: `${theme.spacing(3)} solid ${theme.palette.primary.main}`,

            ':hover, :focus-visible': {
              color: theme.palette.alphaWhite['80'],
              svg: {
                color: theme.palette.alphaWhite['80'],
              },
            },

            ':disabled': {
              color: theme.palette.alphaWhite['30'],
              borderColor: theme.palette.alphaWhite['20'],
            },
          };

        case 'tertiary':
          return {
            color:
              ownerState.endIcon || ownerState.startIcon ? theme.palette.common.black : theme.palette.primary?.main,

            ':hover, :focus-visible': {
              backgroundColor: theme.palette.common.white,
              boxShadow: theme.boxShadows.elevationLight200,
            },

            ':disabled': {
              color: theme.palette.grey['400'],
              svg: {
                color: theme.palette.grey['400'],
              },
            },
          };

        case 'tertiaryOnDark':
          return {
            color: theme.palette.common.white,

            ':hover, :focus-visible': {
              backgroundColor: theme.palette.alphaWhite['20'],
            },

            ':disabled': {
              color: theme.palette.alphaWhite['30'],

              svg: {
                color: theme.palette.alphaWhite['30'],
              },
            },
          };

        case 'link':
          return {
            color: ownerState.startIcon || ownerState.endIcon ? theme.palette.common.black : theme.palette.primary.main,

            ':hover, :focus-visible': {
              backgroundColor: 'transparent',
              svg: {
                color: theme.palette.primary['900'],
              },
            },

            '&.Mui-disabled': {
              color: theme.palette.grey['400'],
              svg: {
                color: theme.palette.grey['400'],
              },
            },
          };

        case 'linkOnDark':
          return {
            color: theme.palette.common.white,

            ':hover, :focus-visible': {
              backgroundColor: 'transparent',
              color: theme.palette.alphaWhite['80'],
              svg: {
                color: theme.palette.alphaWhite['80'],
              },
            },

            '&.Mui-disabled': {
              color: theme.palette.grey['700'],

              svg: {
                color: theme.palette.grey['700'],
              },
            },
          };

        case 'textLink':
          return {
            color: theme.palette.primary.main,
            textDecoration: 'underline',

            ':hover, :focus-visible': {
              backgroundColor: 'transparent',
              color: theme.palette.primary['900'],
              textDecoration: 'underline',
            },
          };

        case 'textLinkOnDark':
          return {
            color: theme.palette.common.white,
            textDecoration: 'underline',

            ':hover, :focus-visible': {
              backgroundColor: 'transparent',
              color: theme.palette.alphaWhite['90'],
              textDecoration: 'underline',
            },
          };

        case 'menuLink':
          return {
            color: theme.palette.grey['900'],

            ':active, :focus-within, .active': {
              '.buttonText': {
                color: theme.palette.primary.main,
              },
            },

            ':hover': {
              backgroundColor: 'transparent',
            },
          };

        case 'menuLinkOnDark':
          return {
            color: theme.palette.common.white,

            ':hover': {
              backgroundColor: 'transparent',
            },
          };

        case 'verticalMenuLink':
          return {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.grey['900'],

            svg: {
              color: theme.palette.primary.main,
            },
          };

        case 'verticalMenuLinkOnDark':
          return {
            color: theme.palette.common.white,
            svg: {
              color: theme.palette.primary.main,
            },

            ':hover, :focus-visible': {
              backgroundColor: theme.palette.alphaWhite['20'],
            },
          };
      }
    },
    startIcon: ({ ownerState }) => getIconStyle(ownerState),
    endIcon: ({ ownerState }) => getIconStyle(ownerState),
  },
  variants: [
    {
      props: { variant: 'large' },
      style: {
        ...sharedButtonStyles,
        fontSize: theme.spacing(18),
        padding: theme.spacing(16, 24),
        lineHeight: theme.spacing(21),
      },
    },
    {
      props: { variant: 'small' },
      style: {
        ...sharedButtonStyles,
        fontSize: theme.spacing(16),
        lineHeight: theme.spacing(18.75),
        minWidth: 'auto',
        padding: theme.spacing(13, 24, 12),
        whiteSpace: 'nowrap',

        '.buttonText': {
          padding: '0',
        },
      },
    },
    {
      props: { variant: 'filter' },
      style: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        fontWeight: 400,
        lineHeight: theme.spacing(21),
        padding: theme.spacing(11.5, 6, 11.5, 14),
        border: `${theme.spacing(2)} solid transparent`,
        gap: theme.spacing(4),
        boxShadow: theme.boxShadows.elevationLight400,
        borderRadius: theme.spacing(12),
        color: theme.palette.common.black,
        textTransform: 'uppercase',
        backgroundColor: theme.palette.common.white,

        '&:hover': {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    {
      props: { variant: 'filterReset' },
      style: {
        ...theme.typography.bodyM,
        backgroundColor: 'transparent',
        textTransform: 'capitalize',
        display: 'flex',
        gap: theme.spacing(4),
        padding: 0,
        color: theme.palette.common.black,

        '&:hover': {
          backgroundColor: 'transparent',
        },

        '.MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    {
      props: { variant: 'link' },
      style: {
        ...sharedButtonStyles,
        minWidth: 'unset',
        padding: 0,
        fontSize: theme.spacing(18),
        lineHeight: theme.spacing(21),
        '.buttonText': { padding: 0 },
      },
    },
    {
      props: { variant: 'textLink' },
      style: {
        padding: 0,
        textTransform: 'unset',
        fontSize: '1em',
        display: 'inline',
        lineHeight: 1,
      },
    },
    {
      props: { variant: 'menuLink' },
      style: {
        padding: 0,
        fontSize: theme.spacing(14),
        lineHeight: theme.spacing(16),
        fontWeight: 500,
        minWidth: 'unset',

        ':hover, :active, :focus-within, .active': {
          '.buttonText:after': {
            transform: 'scale(1)',
            borderBottomColor: theme.palette.primary.main,
          },
        },

        '.buttonText': {
          position: 'relative',

          '&:after': {
            content: '""',
            position: 'absolute',
            width: '100%',
            borderBottom: `${theme.spacing(2)} solid transparent`,
            bottom: theme.spacing(-6),
            left: 0,
            transform: 'scale(0)',
            transition: 'transform 0.25s',
          },
        },
      },
    },
    {
      props: { variant: 'verticalMenuLink' },
      style: {
        minHeight: theme.spacing(44),
        textTransform: 'unset',
        display: 'flex',
        width: '100%',
        fontSize: theme.spacing(16),
        padding: theme.spacing(6, 0, 6, 8),
        alignItems: 'center',

        '.MuiButton-endIcon': { margin: 0 },

        '.buttonText': {
          marginRight: 'auto',
        },
      },
    },
  ],
};

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primary: true;
    primaryOnDark: true;
    secondary: true;
    secondaryOnDark: true;
    tertiary: true;
    tertiaryOnDark: true;
    link: true;
    linkOnDark: true;
    textLink: true;
    textLinkOnDark: true;
    menuLink: true;
    menuLinkOnDark: true;
    verticalMenuLink: true;
    verticalMenuLinkOnDark: true;
  }

  interface ButtonPropsVariantOverrides {
    small: true;
    large: true;
    textLink: true;
    filter: true;
    filterReset: true;
    menuLink: true;
    verticalMenuLink: true;
    link: true;
    text: false;
    contained: false;
  }
}
