import { useEffect, useState } from 'react';

const useSetWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(null);

  useEffect(() => {
    if (windowHeight === null) return;

    document.documentElement.style.setProperty('--app-height', `${windowHeight}px`);
  }, [windowHeight]);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
    const updateAppHeight = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', updateAppHeight);

    return () => {
      window.removeEventListener('resize', updateAppHeight);
    };
  }, []);
};

export default useSetWindowHeight;
