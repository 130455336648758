import { useRouter } from 'next/router';
import { useMemo } from 'react';

const useGetPathName = () => {
  const { asPath } = useRouter();

  return useMemo(
    () =>
      `page-${
        asPath === '/'
          ? 'home'
          : asPath
              .split('/')
              .filter((p) => p !== '')
              .join(' ')
      }`,
    [asPath]
  );
};

export default useGetPathName;
