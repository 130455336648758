import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

declare module '@mui/material/FormControl' {
  interface FormControlPropsColorOverrides {
    primaryOnDark: true;
    successOnDark: true;
  }
}

const rootStylesMap: { primary; primaryOnDark; success?; successOnDark? } = {
  primary: {
    '.Mui-focused.MuiInputLabel-root, &:hover .MuiInputLabel-root': {
      color: theme.palette.grey['900'],
    },

    '.MuiInput-root': {
      marginTop: theme.spacing(12),

      '&:before, &:after': {
        borderBottom: `${theme.spacing(1)} solid ${theme.palette.grey['600']}`,
      },

      '&.Mui-focused:after, &.Mui-focused:before': {
        borderBottomColor: theme.palette.grey['900'],
      },

      '&.Mui-disabled:after, &.Mui-disabled:before': {
        borderBottomColor: theme.palette.grey['400'],
      },

      '.MuiIcon-root': {
        color: theme.palette.primary.main,
      },

      '&.Mui-disabled .MuiIcon-root': {
        color: theme.palette.grey['400'],
      },
    },

    '.MuiInputBase-input': {
      padding: theme.spacing(12, 0),
      ...theme.typography.bodyL,
    },

    '.MuiInputLabel-standard': {
      ...theme.typography.bodyL,
      color: theme.palette.grey['600'],

      '&.MuiFormLabel-filled': {
        color: theme.palette.grey['900'],
      },

      '&.Mui-focused + .MuiInput-root > .MuiInputBase-input': {
        color: theme.palette.grey['600'],
      },

      '&:not(.Mui-focused).Mui-filled  + .MuiInput-root > .MuiInputBase-input': {
        color: theme.palette.grey['900'],
      },

      '&.Mui-disabled': {
        color: theme.palette.grey['400'],

        '.MuiIcon-root': {
          color: theme.palette.grey['400'],
        },
      },
    },

    '.MuiFormHelperText-root': {
      ...theme.typography.caption,
      marginTop: theme.spacing(4),
      color: theme.palette.grey['500'],

      '&.Mui-disabled': {
        color: theme.palette.grey['400'],
      },
    },
  },
  primaryOnDark: {
    '.Mui-focused.MuiInputLabel-root, &:hover .MuiInputLabel-root': {
      color: theme.palette.common.white,
    },

    '.MuiInput-root:not(.Mui-disabled)': {
      marginTop: theme.spacing(12),

      '.MuiIcon-root': {
        color: theme.palette.common.white,
      },

      '&:before, &:after': {
        borderBottom: `${theme.spacing(1)} solid ${theme.palette.alphaWhite['80']}`,
      },

      '&.Mui-focused:not(.Mui-error):after, &.Mui-focused:not(.Mui-error):before': {
        borderBottomColor: theme.palette.common.white,
      },
    },

    '.MuiInput-root.Mui-disabled': {
      '&:after, &:before': {
        borderBottom: `${theme.spacing(1)} solid ${theme.palette.alphaWhite['30']}`,
      },
    },

    ':hover > .MuiInputBase-root:not(.Mui-error) > .MuiInput-root:not(.Mui-disabled)': {
      '&:before, &:after': {
        borderBottom: `${theme.spacing(1)} solid ${theme.palette.common.white}`,
      },
    },

    '.MuiInputBase-input': {
      ...theme.typography.bodyL,
      padding: theme.spacing(12, 0),
      color: theme.palette.alphaWhite['80'],

      '&.Mui-disabled + .MuiInputAdornment-root > .MuiIcon-root': {
        color: theme.palette.alphaWhite['30'],
      },
    },

    '.MuiInputLabel-standard': {
      ...theme.typography.bodyL,
      color: theme.palette.alphaWhite['80'],

      '&.Mui-focused + .MuiInput-root > .MuiInputBase-input': {
        color: theme.palette.common.white,
      },

      '&.Mui-disabled': {
        color: theme.palette.alphaWhite['30'],

        '.MuiIcon-root': {
          color: theme.palette.alphaWhite['30'],
        },
      },
    },

    '.MuiFormHelperText-root': {
      ...theme.typography.caption,
      marginTop: theme.spacing(4),
      color: theme.palette.alphaWhite['80'],

      '&.Mui-disabled': {
        color: theme.palette.alphaWhite['30'],
      },
    },
  },
};

rootStylesMap.success = {
  ...rootStylesMap.primary,
  ':hover .MuiFormLabel-root, .Mui-focused.MuiFormLabel-root, .MuiFormLabel-filled.MuiFormLabel-root, .MuiFormLabel-root, .MuiFormHelperText-root':
    {
      color: theme.palette.success.main,
    },
  '.MuiInput-root:not(.Mui-disabled)': {
    '&:before, &:after': {
      borderBottom: `${theme.spacing(1)} solid ${theme.palette.success.main}`,
    },

    '.MuiIcon-root': {
      color: theme.palette.success.main,
    },
  },
};

rootStylesMap.successOnDark = {
  ...rootStylesMap.primaryOnDark,
  ':hover .MuiFormLabel-root, .Mui-focused.MuiFormLabel-root, .MuiFormLabel-filled.MuiFormLabel-root, .MuiFormLabel-root, .MuiFormHelperText-root':
    {
      color: theme.palette.success.light,
    },
  '.MuiInput-root:not(.Mui-disabled)': {
    '&:before, &:after': {
      borderBottom: `${theme.spacing(1)} solid ${theme.palette.success.light}`,
    },

    '.MuiIcon-root': {
      color: theme.palette.success.light,
    },
  },
};

export const MuiFormControl: MuiComponentOverride<'MuiFormControl'> = {
  defaultProps: {
    variant: 'standard',
    fullWidth: true,
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...rootStylesMap[ownerState.color],
      '.MuiFormLabel-root.Mui-error, .MuiFormHelperText-root.Mui-error': {
        color: theme.palette.error.dark,
      },
      '.MuiInput-root.Mui-error': {
        '&:before, &:after': {
          borderBottom: `${theme.spacing(1)} solid ${theme.palette.error.dark}`,
        },

        '.MuiIcon-root': {
          color: theme.palette.error.dark,
        },
      },
    }),
  },
};
