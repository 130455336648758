import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

export const MuiMenu: MuiComponentOverride<'MuiMenu'> = {
  styleOverrides: {
    paper: {
      borderRadius: theme.borderRadius.card,
      boxShadow: theme.boxShadows.elevationLight400,
    },
    list: {
      padding: 0,
      display: 'flex',
      flexFlow: 'column nowrap',

      '.MuiMenuItem-root': {
        borderRadius: theme.spacing(4),
        padding: theme.spacing(16),
      },
    },
  },
};
