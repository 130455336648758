import { MuiComponentOverride } from '@model/mui-component-override';

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    primaryOnDark: true;
  }
}

export const MuiTextField: MuiComponentOverride<'MuiTextField'> = {
  defaultProps: {
    variant: 'standard',
    fullWidth: true,
  },
};
