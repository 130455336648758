import { ThemeProvider } from '@mui/material/styles';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import '../src/styles/global.scss';
import NextNProgress from 'nextjs-progressbar';
import useSetWindowHeight from '../src/hooks/useSetWindowHeight';
import useSetBodyClassName from '../src/hooks/useSetBodyClassName';

import { LicenseInfo } from '@mui/x-license-pro';

import 'plyr-react/plyr.css';

import theme from '@mui/theme';

import '@mui/mui-colors';
import '@mui/mui-typography';
import '@mui/mui-border-radius';
import '@mui/mui-components';
import { useEffect } from 'react';
import { runCookieScripts } from '@utils/functions/run-cookie-scripts';
import wbgInitMatomo from '@utils/functions/wbg-init-matomo';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import useGetPathName from '@hooks/useGetPathName';
import { useRouter } from 'next/router';
import 'dayjs/locale/it';
import 'dayjs/locale/en';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';

if (process.env.NODE_ENV === 'development') {
  // process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_PRO_KEY);
const queryClient = new QueryClient();

function App(props: AppProps) {
  const { Component, pageProps } = props;
  const router = useRouter();
  const path = useGetPathName();

  useEffect(() => {
    // redirect preview with query param slug
    const { search, origin } = window.location;
    const urlParams = new URLSearchParams(search);
    const slugParam = urlParams.get('slug');
    if (slugParam) {
      const slugParam = new URLSearchParams(search).get('slug');
      const slug = slugParam ? slugParam : '';
      window.location.href = origin + slug;
    }
  }, []);

  useSetBodyClassName(path);
  useSetWindowHeight();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENABLE_COOKIE !== 'true') return;
    wbgInitMatomo(process.env.NEXT_PUBLIC_MULTISITE_CONTEXT_TAX);
  }, []);

  useEffect(() => {
    runCookieScripts();
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY ?? ''} language={router.locale}>
      <AppCacheProvider {...props}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <NextNProgress
              options={{ easing: 'ease', speed: 500, showSpinner: false }}
              height={3}
              color="#DB002F"
              startPosition={0.3}
              stopDelayMs={200}
              showOnShallow={true}
            />

            <Component {...pageProps} />
          </QueryClientProvider>
        </ThemeProvider>
      </AppCacheProvider>
    </GoogleReCaptchaProvider>
  );
}

export default appWithTranslation(App);
