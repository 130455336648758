import theme from '@mui/theme';
import { CSSProperties } from 'react';

theme.typography.h1 = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(40),
  lineHeight: theme.spacing(48),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(56),
    lineHeight: theme.spacing(68),
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(72),
    lineHeight: theme.spacing(80),
  },
};

theme.typography.h1Light = {
  ...theme.typography.h1,
  fontWeight: 300,
};

theme.typography.h2 = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(36),
  lineHeight: theme.spacing(44),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(48),
    lineHeight: theme.spacing(58),
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(56),
    lineHeight: theme.spacing(72),
  },
};

theme.typography.h2Light = {
  ...theme.typography.h2,
  fontWeight: 300,
};

theme.typography.h3 = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(32),
  lineHeight: theme.spacing(40),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(40),
    lineHeight: theme.spacing(48),
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(44),
    lineHeight: theme.spacing(56),
  },
};

theme.typography.h3Light = {
  fontWeight: 300,
};

theme.typography.h4 = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(28),
  lineHeight: theme.spacing(36),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(32),
    lineHeight: theme.spacing(40),
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(36),
    lineHeight: theme.spacing(48),
  },
};

theme.typography.h4Medium = {
  ...theme.typography.h4,
  fontWeight: 500,
};

theme.typography.h5 = {
  fontWeight: 700,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(22),
  lineHeight: theme.spacing(33),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(24),
    lineHeight: theme.spacing(36),
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(28),
    lineHeight: theme.spacing(40),
  },
};

theme.typography.h5Mono = {
  ...theme.typography.h5,
  fontFamily: 'Roboto Mono, sans-serif',
};

theme.typography.h6 = {
  fontWeight: 700,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(20),
  lineHeight: theme.spacing(30),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(22),
    lineHeight: theme.spacing(33),
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(24),
    lineHeight: theme.spacing(36),
  },
};
theme.typography.h6Mono = {
  ...theme.typography.h6,
  fontFamily: 'Roboto Mono, sans-serif',
};

theme.typography.subtitle = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(18),
  lineHeight: theme.spacing(27),

  [theme.breakpoints.up('sm')]: {
    fontSize: theme.spacing(20),
    lineHeight: theme.spacing(30),
  },

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(22),
    lineHeight: theme.spacing(33),
  },
};

theme.typography.subtitleBold = {
  ...theme.typography.subtitle,
  fontWeight: 700,
};

theme.typography.bodyL = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(16),
  lineHeight: theme.spacing(24),

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(18),
    lineHeight: theme.spacing(32),
  },
};

theme.typography.bodyLBold = {
  ...theme.typography.bodyL,
  fontWeight: 700,
};

theme.typography.bodyM = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',

  fontSize: theme.spacing(14),
  lineHeight: theme.spacing(21),

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(16),
    lineHeight: theme.spacing(24),
  },
};
theme.typography.bodyMBold = {
  ...theme.typography.bodyM,
  fontWeight: 700,
};

theme.typography.bodyS = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',
  fontSize: theme.spacing(12),
  lineHeight: theme.spacing(18),

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(14),
    lineHeight: theme.spacing(21),
  },
};

theme.typography.bodySMono = {
  ...theme.typography.bodyS,
  fontFamily: 'Roboto Mono, monospace',
};

theme.typography.bodySBold = {
  ...theme.typography.bodyS,
  fontWeight: 700,
};

theme.typography.overline = {
  fontWeight: 400,
  textTransform: 'uppercase',
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: '.1em',

  fontSize: theme.spacing(14),
  lineHeight: theme.spacing(21),

  [theme.breakpoints.up('lg')]: {
    fontSize: theme.spacing(16),
    lineHeight: theme.spacing(24),
  },
};
theme.typography.overlineMono = {
  ...theme.typography.overline,
  fontFamily: 'Roboto Mono, sans-serif',
};

theme.typography.caption = {
  fontWeight: 400,
  fontFamily: 'Roboto, sans-serif',
  letterSpacing: 'auto',
  fontSize: theme.spacing(12),
  lineHeight: theme.spacing(18),
};
theme.typography.captionMedium = {
  ...theme.typography.caption,
  fontWeight: 500,
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Light: CSSProperties;
    h2Light: CSSProperties;
    h3Light: CSSProperties;
    h4Medium: CSSProperties;
    h5Mono: CSSProperties;
    h6Mono: CSSProperties;
    subtitleBold: CSSProperties;
    bodyL: CSSProperties;
    bodyM: CSSProperties;
    bodyS: CSSProperties;
    bodySMono: CSSProperties;
    bodyLBold: CSSProperties;
    bodyMBold: CSSProperties;
    bodySBold: CSSProperties;
    overlineMono: CSSProperties;
    captionMedium: CSSProperties;
  }

  interface TypographyVariantsOptions {
    h1Light?: CSSProperties;
    h2Light?: CSSProperties;
    h3Light?: CSSProperties;
    h4Medium?: CSSProperties;
    h5Mono?: CSSProperties;
    h6Mono?: CSSProperties;
    subtitleBold?: CSSProperties;
    bodyL?: CSSProperties;
    bodyM?: CSSProperties;
    bodyS?: CSSProperties;
    bodyLBold?: CSSProperties;
    bodyMBold?: CSSProperties;
    bodySBold?: CSSProperties;
    overlineMono?: CSSProperties;
    captionMedium?: CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Light: true;
    h2Light: true;
    h3Light: true;
    h4Medium: true;
    h5Mono: true;
    h6Mono: true;
    subtitleBold: true;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    bodyL: true;
    bodyM: true;
    bodyS: true;
    bodySMono: true;
    bodyLBold: true;
    bodyMBold: true;
    bodySBold: true;
    overlineMono: true;
    captionMedium: true;
  }
}
