import { createTheme, ThemeOptions } from '@mui/material/styles';
import { BoxShadowsModel } from '@model/box-shadows.model';
import { LinearGradientModel } from '@model/linear-gradient.model';
import { BorderRadiusModel } from '@model/border-radius.model';

declare module '@mui/material' {
  interface Theme {
    boxShadows: BoxShadowsModel;
    linearGradients: Partial<LinearGradientModel>;
    borderRadius: BorderRadiusModel;
  }

  interface ThemeOptions {
    boxShadows?: BoxShadowsModel;
    linearGradients?: Partial<LinearGradientModel>;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xlg: true;
    xxlg: true;
  }
}

export const themeOptions: ThemeOptions = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xlg: 1200,
      xxlg: 1300,
      xl: 1400,
      xxl: 1920,
    },
  },

  typography: {
    htmlFontSize: 16,
  },

  spacing: (factor) => `${factor / 16}rem`,

  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
    },
    primary: {
      main: '#DB002F',
      light: '#FFC5D0',
      dark: '#C0001A',
      contrastText: '#ffffff',
      900: '#c0001a',
      800: '#ce0027',
      700: '#db002f',
      600: '#ee1c35',
      500: '#fe2936',
      400: '#fa4350',
      300: '#ee6872',
      200: '#f99096',
      100: '#ffc5d0',
      50: '#ffe4ec',
    },
    secondary: {
      main: '#88A2AA',
      contrastText: '#ffffff',
      900: '#43575d',
      800: '#4e666e',
      700: '#5c7881',
      600: '#6d8d97',
      500: '#88a2aa',
      400: '#a6b9bf',
      300: '#bccbcf',
      200: '#cdd8db',
      100: '#d9e2e4',
      50: '#e3e9eb',
    },
    secondaryOnDark: { main: '#88A2AA' },
    grey: {
      50: '#FAFAFA',
      100: '#F4F4F4',
      200: '#ECECEC',
      300: '#DEDEDE',
      400: '#BBBBBB',
      500: '#9B9B9B',
      600: '#727272',
      700: '#5F5F5F',
      800: '#404040',
      900: '#1F1F1F',
    },
    success: {
      main: '#3B8A27',
      light: '#72C242',
      dark: '#4F9E30',
      contrastText: '#D5EDC6',
    },
    successOnDark: {
      main: '#3B8A27',
      light: '#72C242',
      dark: '#4F9E30',
      contrastText: '#D5EDC6',
    },
    warning: {
      main: '#E57300',
      light: '#FFBE16',
      dark: '#FF8C00',
      contrastText: '#FFE8AD',
    },
    error: {
      main: '#800000',
      light: '#FD0000',
      dark: '#CC0000',
      contrastText: '#FFCCCC',
    },
    info: {
      main: '#88A2AA',
      light: '#CDD8DB',
      dark: '#43575D',
      contrastText: '#E3E9EB',
    },
    alphaBlack: {
      90: 'rgba(0, 0, 0, .9)',
      80: 'rgba(0, 0, 0, .8)',
      70: 'rgba(0, 0, 0, .7)',
      60: 'rgba(0, 0, 0, .6)',
      50: 'rgba(0, 0, 0, .5)',
      40: 'rgba(0, 0, 0, .4)',
      30: 'rgba(0, 0, 0, .3)',
      20: 'rgba(0, 0, 0, .2)',
      10: 'rgba(0, 0, 0, .1)',
    },
    alphaGrey: {
      90: 'rgba(155,155,155,0.9)',
      80: 'rgba(155,155,155,0.8)',
      70: 'rgba(155,155,155,0.7)',
      60: 'rgba(155,155,155,0.6)',
      50: 'rgba(155,155,155,0.5)',
      40: 'rgba(155,155,155,0.4)',
      30: 'rgba(155,155,155,0.3)',
      20: 'rgba(155,155,155,0.2)',
      10: 'rgba(155,155,155,0.1)',
    },
    alphaWhite: {
      90: 'rgba(255, 255, 255, .9)',
      80: 'rgba(255, 255, 255, .8)',
      70: 'rgba(255, 255, 255, .7)',
      60: 'rgba(255, 255, 255, .6)',
      50: 'rgba(255, 255, 255, .5)',
      40: 'rgba(255, 255, 255, .4)',
      30: 'rgba(255, 255, 255, .3)',
      20: 'rgba(255, 255, 255, .2)',
      10: 'rgba(255, 255, 255, .1)',
    },

    alphaRed: {
      90: 'rgba(219, 0, 47, .9)',
      80: 'rgba(219, 0, 47, .8)',
      70: 'rgba(219, 0, 47, .7)',
      60: 'rgba(219, 0, 47, .6)',
      50: 'rgba(219, 0, 47, .5)',
      40: 'rgba(219, 0, 47, .4)',
      30: 'rgba(219, 0, 47, .3)',
      20: 'rgba(219, 0, 47, .2)',
      10: 'rgba(219, 0, 47, .1)',
    },
    alphaBlue: {
      90: `rgba(92, 120, 129, .9)`,
      80: `rgba(92, 120, 129, .8)`,
      70: `rgba(92, 120, 129, .7)`,
      60: `rgba(92, 120, 129, .6)`,
      50: `rgba(92, 120, 129, .5)`,
      40: `rgba(92, 120, 129, .4)`,
      30: `rgba(92, 120, 129, .3)`,
      20: `rgba(92, 120, 129, .2)`,
      10: `rgba(92, 120, 129, .1)`,
    },
  },
  boxShadows: {
    backgroundTransitionDarkToLight: '0px -8px 24px rgba(0, 0, 0, 0.1), 0px 24px 56px rgba(0, 0, 0, 0.4)',
    backgroundTransitionLightToDark: '0px -24px 56px rgba(0, 0, 0, 0.2);',
    elevationLight200: '0px 4px 6px -4px rgba(36, 36, 36, 0.05), 0px 8px 8px -4px rgba(36, 36, 36, 0.05)',
    elevationLight400: '0px 6px 12px -6px rgba(36, 36, 36, 0.1), 0px 8px 24px -4px rgba(36, 36, 36, 0.1)',
    elevationLight600: '0px 8px 18px -6px rgba(36, 36, 36, 0.1), 0px 12px 42px -4px rgba(36, 36, 36, 0.1)',
    elevationLight800: '0px 8px 28px -6px rgba(36, 36, 36, 0.1), 0px 18px 88px -4px rgba(36, 36, 36, 0.1)',
    elevationDark200: '0px 4px 6px -4px rgba(255, 255, 255, 0.1), 0px 8px 8px -4px rgba(255, 255, 255, 0.1)',
    elevationDark400: '0px 6px 12px -6px rgba(255, 255, 255, 0.2), 0px 8px 24px -4px rgba(255, 255, 255, 0.2)',
    elevationDark600: '0px 8px 18px -6px rgba(255, 255, 255, 0.3), 0px 12px 42px -4px rgba(255, 255, 255, 0.3)',
    elevationDark800: '0px 8px 28px -6px rgba(255, 255, 255, 0.5), 0px 18px 88px -4px rgba(255, 255, 255, 0.5)',
    elevationDarkBlue800: '0px 18px 88px -4px #88A2AA80, 0px 8px 28px -6px #88A2AA80',
  },
};

const theme = createTheme(themeOptions);

export default theme;
