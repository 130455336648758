import Cookies from 'js-cookie';
import wbgInitTwitter from '@utils/functions/wbg-init-twitter';
import wbgInitLinkedin from '@utils/functions/wbg-init-linkedin';
import wbgInitFacebook from '@utils/functions/wbg-init-facebook';

export const runCookieScripts = () => {
  if (process.env.NEXT_PUBLIC_ENABLE_COOKIE !== 'true') return;

  if (Cookies.get('twitter_cookie_consent') === '') {
    wbgInitTwitter();
  }

  if (Cookies.get('linkedin_cookie_consent') === '') {
    wbgInitLinkedin();
  }

  if (Cookies.get('facebook_cookie_consent') === '') {
    wbgInitFacebook();
  }
};
