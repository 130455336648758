import { MuiComponentOverride } from '@model/mui-component-override';

export const MuiSelect: MuiComponentOverride<'MuiSelect'> = {
  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        backgroundColor: 'white',
      },
    },
  ],
};
