import theme from './theme';
import { MuiComponentOverride } from '@model/mui-component-override';

export const MuiAccordion: MuiComponentOverride<'MuiAccordion'> = {
  variants: [
    {
      props: { variant: 'accordionMenuLight' },
      style: {
        padding: theme.spacing(10, 8),
        background: theme.palette.common.white,
        color: theme.palette.common.black,
        svg: { color: theme.palette.primary.main },
      },
    },
    {
      props: { variant: 'accordionMenuDark' },
      style: {
        padding: theme.spacing(10, 8),
        background: theme.palette.common.black,
        color: theme.palette.common.white,
        svg: { color: theme.palette.common.white },
      },
    },
  ],
};
