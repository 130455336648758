import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

const labelStylesMap = {
  onLight: {
    color: theme.palette.grey['900'],
  },
  onDark: {
    color: theme.palette.common.white,
  },
};

const rootStylesMap = {
  onLight: {
    '&:not(.Mui-disabled):hover': {
      svg: {
        color: theme.palette.primary['900'],
      },
    },

    '&.Mui-disabled': {
      '.MuiFormControlLabel-label': {
        color: theme.palette.grey['200'],
      },

      svg: {
        color: theme.palette.grey['200'],
      },
    },
  },
  onDark: {
    '&:not(.Mui-disabled):hover': {
      '.MuiFormControlLabel-label': {
        color: theme.palette.grey['200'],
      },

      '.MuiCheckbox-root.Mui-checked:before': {
        backgroundColor: theme.palette.primary['900'],
      },

      svg: {
        color: theme.palette.grey['100'],
      },
    },

    '&.Mui-disabled': {
      '.MuiFormControlLabel-label': {
        color: theme.palette.grey['700'],
      },

      '.MuiCheckbox-root.Mui-checked:before': {
        backgroundColor: theme.palette.alphaWhite['30'],
      },

      svg: {
        color: theme.palette.grey['700'],
      },
    },
  },
};

export const MuiFormControlLabel: MuiComponentOverride<'MuiFormControlLabel'> = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...rootStylesMap[ownerState.color || 'onLight'],
    }),
    label: ({ ownerState }) => ({
      ...theme.typography.bodyS,
      ...labelStylesMap[ownerState.color || 'onLight'],
    }),
  },
};
