import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    primaryOnDark: true;
  }
}

const radioStylesMap = {
  primary: {
    color: theme.palette.primary.main,
  },
  primaryOnDark: {
    color: theme.palette.common.white,
  },
};

export const MuiRadio: MuiComponentOverride<'MuiRadio'> = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      ...radioStylesMap[ownerState.color],
    }),
  },
};
