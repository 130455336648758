import { useEffect } from 'react';
import removeClassByPrefix from '../utils/functions/removeClassByPrefix';

const useSetBodyClassName = (path: string) => {
  useEffect(() => {
    removeClassByPrefix(document.body, 'page-');
    document.body.className = path;
  }, [path]);
};

export default useSetBodyClassName;
