import theme from '@mui/theme';
import { AlphaColors } from '@model/types';
import { SimplePaletteColorOptions } from '@mui/material';

theme.linearGradients = {
  rightBlue: 'linear-gradient(90deg, #D9E2E4 0%, rgba(255, 255, 255, 0.1) 100%)',
  rightGrey: 'linear-gradient(90deg, #727272 0%, rgba(255, 255, 255, 0.1) 100%)',
  rightRed: 'linear-gradient(90deg, #DB002F 0%, rgba(255, 255, 255, 0.1) 100%)',
  rightColor: 'linear-gradient(90deg, #C0001A 0%, #000000 100%)',
};

declare module '@mui/material/styles' {
  interface Palette {
    alphaBlack?: AlphaColors;
    alphaWhite: AlphaColors;
    alphaRed: AlphaColors;
    alphaGrey: AlphaColors;
    alphaBlue: AlphaColors;
    secondaryOnDark: SimplePaletteColorOptions;
    primaryOnDark: SimplePaletteColorOptions;
    successOnDark: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    alphaBlack?: AlphaColors;
    alphaBlue?: AlphaColors;
    alphaRed?: AlphaColors;
    alphaGrey?: AlphaColors;
    alphaWhite?: AlphaColors;
    primaryOnDark?: SimplePaletteColorOptions;
    secondaryOnDark?: SimplePaletteColorOptions;
    successOnDark?: SimplePaletteColorOptions;
  }
}

theme.palette.primaryOnDark = {
  main: theme.palette.common.white,
};
