import { MuiComponentOverride } from '@model/mui-component-override';
import theme from '@mui/theme';

export const MuiChip: MuiComponentOverride<'MuiChip'> = {
  styleOverrides: {
    root: {
      height: 'unset',
      padding: theme.spacing(3, 12),
    },
    label: {
      padding: 0,
      ...theme.typography.caption,
    },
  },
  variants: [
    {
      props: { variant: 'light' },
      style: {
        backgroundColor: theme.palette.secondary['50'],
        color: theme.palette.grey['700'],
      },
    },
    {
      props: { variant: 'dark' },
      style: {
        backgroundColor: theme.palette.secondary['900'],
        color: theme.palette.common.white,
        ':hover': {
          backgroundColor: theme.palette.secondary['800'],
        },
      },
    },
  ],
};

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    light: true;
    dark: true;
  }
}
