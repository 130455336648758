import { CTX_TAX_MAP, MATOMO_SRC_MAP } from '@utils/constants/constants';

const CONTEXTS = Object.values(CTX_TAX_MAP);

/**
 *
 * @param {string} ctxTax
 * @return {boolean}
 */
const existsContext = (ctxTax) => CONTEXTS.includes(ctxTax);

/**
 *
 * @param {string} ctxTax
 * @return {void}
 */
function wbgMatomo(ctxTax) {
  var _mtm = (window._mtm = window._mtm || []);
  _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
  var d = document,
    g = d.createElement('script'),
    s = d.getElementsByTagName('script')[0];
  g.async = true;
  g.src = existsContext(ctxTax) ? MATOMO_SRC_MAP[ctxTax] : MATOMO_SRC_MAP[CTX_TAX_MAP.GLOBAL];
  s.parentNode.insertBefore(g, s);
}

export default wbgMatomo;
